class AppConfig {
  // TODO: Add default config.
  private config: Record<string, unknown> = {};

  setupConfig = (config: Record<string, unknown>) => {
    this.config = { ...this.config, ...config };
  };

  getConfig = <T = unknown>(key: string): T => this.config[key] as T;

  setConfig = (key: string, value: unknown) => {
    this.config[key] = value;
  };
}

const config = new AppConfig();

export { config as AppConfig };
